import { formatDateShort } from '~/utils/dateUtils'
import type {
  ArticleListElement,
  ArticleListElementSource,
} from '~/typeEntities/stores/Article/models'
import first from '~/utils/global/first'

export const formatArticleListElement = (
  articleListElementSource: ArticleListElementSource
): ArticleListElement => {
  const firstArticleDoctor = first(articleListElementSource.articleDoctors)
  return {
    ...extractArticleListElementSourceColumns(articleListElementSource),
    store: articleListElementSource.store || null,
    writer: articleListElementSource.writer || null,
    path: (() => {
      if (articleListElementSource.store) {
        return `/clinics/${articleListElementSource.store.id}/articles/${articleListElementSource.id}`
      } else {
        switch (articleListElementSource.contentType) {
          case 'column':
            return `/media/columns/${articleListElementSource.id}`
          case 'fixed':
            return `/media/fixed/${articleListElementSource.id}`
          case 'treatment_column':
            return `/media/treatment_column/articles/${articleListElementSource.id}`
          default:
            return `/media/news/${articleListElementSource.id}`
        }
      }
    })(),
    featureLabel: (() => {
      switch (articleListElementSource.featureType) {
        case 'doctor_interview':
          return '先生ガチインタビュー'
        case 'medical_case_explanation':
          return '症例ガチ解説'
        default:
          return ''
      }
    })(),
    thumbnailUrl: articleListElementSource.photo?.imageUrl || '/noimage.jpg',
    formatedPublishedAt: formatDateShort(articleListElementSource.publishedAt),
    pickupArticleComment: articleListElementSource.pickupArticle?.comment || '',
    pickupArticleSubText: articleListElementSource.pickupArticle?.subText || '',
    interviewedDoctorName: firstArticleDoctor
      ? `${firstArticleDoctor.doctor.name} 先生`
      : '',
  }
}

const extractArticleListElementSourceColumns = (
  articleListElementSource: ArticleListElementSource
): ArticleListElementSource => {
  const {
    id,
    title,
    publishedAt,
    status,
    contentType,
    featureType,
    articleDoctors,
    writer,
    photo,
    store,
    pickupArticle,
  } = articleListElementSource
  return {
    id,
    title,
    publishedAt,
    status,
    contentType,
    featureType,
    articleDoctors,
    writer,
    photo,
    store,
    pickupArticle,
  }
}
